import React from "react";
import { graphql, Link } from "gatsby";
import PortableText from "../components/Blog/portableText";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import tw from "twin.macro";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTA";
import menu from "../images/9.0 Company Profile Pages/menu.svg";

export const query = graphql`
  query PortfolioTemplateQuery($id: String!) {
    portfolio: sanityPortfolio(id: { eq: $id }) {
      id
      seoTitle
      metaDescription
      title
      logo {
        asset {
          gatsbyImageData(layout: CONSTRAINED, width: 160)
        }
      }
      industry {
        title
      }
      dateInvested
      status
      website
      _rawAboutTheCompany
    }
  }
`;

const StyledContent = styled.div`
  p,
  span,
  b {
    ${tw`md:text-xl`}
  }
`;

const Template = ({ data, pageContext }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title={data.portfolio.seoTitle}
        description={data.portfolio.metaDescription}
        // openGraphImage={data.portfolio.openGraphImage && data.portfolio.openGraphImage.asset.url}
        // twitterOpenGraphImage={
        //   data.portfolio.twitterCardImage && data.portfolio.twitterCardImage.asset.url
        // }
      />

      <section className="relative h-[104px] overflow-hidden md:h-[200px]">
        <div className="absolute top-0 left-0 hidden h-full w-full md:block">
          <StaticImage
            src="../images/9.0 Company Profile Pages/1.0 banner.jpg"
            loading="eager"
            className="h-full"
          />
        </div>
        <div className="absolute top-0 left-0 h-full w-full md:hidden">
          <StaticImage
            src="../images/9.0 Company Profile Pages/1.0 banner-mobile.jpg"
            loading="eager"
            className="h-full"
          />
        </div>
      </section>

      <section className="-mt-20 pt-7 pb-20 md:-mt-36 md:pt-16 md:pb-28">
        <div className="container">
          {data.portfolio.logo && (
            <div className="mb-4 text-center md:mb-8">
              <GatsbyImage
                image={data.portfolio.logo.asset.gatsbyImageData}
                alt={data.portfolio.name}
                loading="eager"
                className="z-0 w-[104px] rounded-full border-4 border-white md:w-[160px]"
              />
            </div>
          )}

          <div>
            <h1 className="text-center">{data.portfolio.title}</h1>
            <div className="text-center">
            <div className="text-xs font-semibold text-center mb-5 inline-block py-1 px-2 uppercase rounded text-slate-600 bg-slate-200 uppercase last:mr-0 mr-1">
            {data.portfolio.industry.title}
            </div>
            </div>
            {data.portfolio.investor && (
              <div className="mb-6 flex justify-center md:mb-7">
                <div className="inline-block rounded-md bg-primary-500/20 p-2 text-sm font-bold uppercase tracking-wide text-primary-500">
                  {data.portfolio.investor}
                </div>
              </div>
            )}
            <div className="mb-6 flex items-center justify-center text-center">
              {data.portfolio.dateInvested && (
                <div className="font-semibold text-gray-400">
                  Invested: {data.portfolio.dateInvested}
                </div>
              )}

              {data.portfolio.dateInvested && data.portfolio.status && (
                <div className="mx-2 font-semibold text-gray-400">|</div>
              )}

              {data.portfolio.status && (
                <div className="font-semibold text-gray-400">
                  Status: {data.portfolio.status}
                </div>
              )}
            </div>

            <div className="mx-auto mt-16 max-w-[720px] border-t border-gray-200 md:mt-20"></div>

            {/* {(data.portfolio.yearFounded ||
              data.portfolio.teamSize ||
              data.portfolio.yearsExperience) && (
              <div className="mx-auto flex max-w-[584px] rounded-lg border border-gray-300 bg-white text-center md:rounded-lg">
                {data.portfolio.yearFounded && (
                  <div className={`flex-1 px-4 py-6`}>
                    <div className="mb-0.5 font-heading text-xl font-bold tracking-wider text-primary-900 md:mb-3 md:text-4xl">
                      {data.portfolio.yearFounded}
                    </div>
                    <div className="text-sm font-bold uppercase tracking-wide text-gray-400">
                      Founded
                    </div>
                  </div>
                )}

                {data.portfolio.teamSize && (
                  <div className={`flex-1 border-l border-gray-300 px-4 py-6`}>
                    <div className="mb-0.5 font-heading text-xl font-bold tracking-wider text-primary-900 md:mb-3 md:text-4xl">
                      {data.portfolio.teamSize}
                    </div>
                    <div className="text-sm font-bold uppercase tracking-wide text-gray-400">
                      Team Size
                    </div>
                  </div>
                )}

                {data.portfolio.yearsExperience && (
                  <div className={`flex-1 border-l border-gray-300 px-4 py-6`}>
                    <div className="mb-0.5 font-heading text-xl font-bold tracking-wider text-primary-900 md:mb-3 md:text-4xl">
                      {data.portfolio.location}
                    </div>
                    <div className="text-sm font-bold uppercase tracking-wide text-gray-400">
                      Location
                    </div>
                  </div>
                )}
              </div>
            )} */}

            {data.portfolio._rawAboutTheCompany && (
              <div className="mx-auto mb-10 mt-16 max-w-[720px] md:mb-20 md:mt-20">
                <h3>About the Company</h3>
                <StyledContent>
                  <PortableText blocks={data.portfolio._rawAboutTheCompany} />
                </StyledContent>
              </div>
            )}

            {data.portfolio.website && (
              <div className="mb-20 text-center md:mb-26">
                <a
                  href={data.portfolio.website}
                  target="_blank"
                  rel="noreferrer"
                  className="text-secondary-900 no-underline hover:text-secondary-500"
                >
                  <i class="fa-solid fa-link mr-2"></i>
                  {data.portfolio.website}
                </a>
              </div>
            )}

            <div className="mt-16 flex items-center justify-between">
              {pageContext.prev ? (
                <Link
                  to={`/${pageContext.prev.slug.current}/`}
                  className="group inline-flex items-center justify-center space-x-2.5 font-semibold text-black no-underline hover:text-black"
                >
                  <i className="far fa-arrow-left relative right-0 text-xl text-secondary-900 transition-all duration-300 ease-linear group-hover:right-1.5"></i>{" "}
                  <span>Previous</span>
                </Link>
              ) : (
                <div></div>
              )}

              {(pageContext.prev || pageContext.next) && (
                <Link to="/portfolio/">
                  <img src={menu} alt="Menu" />
                </Link>
              )}

              {pageContext.next ? (
                <Link
                  to={`/${pageContext.next.slug.current}/`}
                  className="group inline-flex items-center justify-center space-x-2.5 font-semibold text-black no-underline hover:text-black"
                >
                  <span>Next</span>
                  <i className="far fa-arrow-right relative left-0 text-xl text-secondary-900 transition-all duration-300 ease-linear group-hover:left-1.5"></i>
                </Link>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      </section>

      <CallToAction />
    </Layout>
  );
};

export default Template;
